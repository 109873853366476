import React, { useContext } from 'react';
import CustomHead from 'blocks/CustomHead';
import Head from 'next/head';
import {
  getHomepageBlocks,
  getNinetailedExperiments,
  getNinetailedExperiences,
  LandingPageData
} from 'services/contentful';
import { ServicesContext, Services, StaticProps } from 'services/context';
import { getLocale } from 'services/locale';
import retrieveFromContentfulCache from 'utils/buildCache/retrieveFromContentfulCache';
import { PageProps } from 'types/page';
import HomePage from 'views/HomePage';
import type { AsyncReturnType } from 'type-fest';
import retrieveFromLocaleCache from 'utils/buildCache/retrieveFromLocaleCache';
import { GetStaticPropsContext } from 'next';

function Home(props: any) {
  const { metaTitle, metaImage, metaDescription } = props.homepage;
  const { webStore } = useContext(ServicesContext);

  // Schema markup to improve findability in search engines
  const schemaData = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    address: 'Stephensonstraat 19 1097 BA, Amsterdam',
    email: `${webStore.contact.email}`,
    founder: 'Mark de Lange',
    foundingDate: '2013',
    image:
      'https://ace-tate-res.cloudinary.com/image/upload/v1500456292/:spree/public/spree/products/39/original/logo.resized.png',
    logo: 'https://ace-tate-res.cloudinary.com/image/upload/v1500456292/:spree/public/spree/products/39/original/logo.resized.png',
    name: 'Ace & Tate',
    potentialAction: {
      '@type': 'SearchAction',
      'query-input': 'required name=search_term_string',
      target: `https://www.aceandtate.com/search?query={search_term_string}`
    },
    sameAs: [
      'https://twitter.com/aceandtate',
      'https://www.facebook.com/aceandtate',
      'https://instagram.com/aceandtate/'
    ],
    telephone: `${webStore.contact.officePhone}`,
    url: 'https://aceandtate.com/'
  };

  return (
    <>
      <CustomHead image={metaImage} description={metaDescription} title={metaTitle} />
      <Head>
        <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }} />
      </Head>
      <HomePage {...props} />
    </>
  );
}

export default Home;
interface HomePageProps extends Services {
  homepage: LandingPageData;
  ninetailed: {
    experiences: AsyncReturnType<typeof getNinetailedExperiences>;
    experiments: AsyncReturnType<typeof getNinetailedExperiments>;
  };
}

type GetStaticProps = StaticProps<HomePageProps>;

export async function getStaticProps(props: GetStaticPropsContext & PageProps): Promise<GetStaticProps> {
  const { preview: draftMode = false } = props;
  let locale = getLocale(props.locale);

  // homepage always needs a default locale
  if (!locale) {
    locale = getLocale('nl-en');
  }

  const webStore = await retrieveFromContentfulCache('webStore', locale);

  const [pageLayoutData, homepage, experiences, experiments, messages] = await Promise.all([
    retrieveFromContentfulCache('pageLayoutData', locale, { draftMode }),
    getHomepageBlocks(locale, webStore, { draftMode }),
    retrieveFromContentfulCache('ntExperiences', locale, { draftMode }),
    retrieveFromContentfulCache('ntExperiments', locale, { draftMode }),
    retrieveFromLocaleCache(locale)
  ]);

  return {
    props: {
      draftMode,
      footer: pageLayoutData.footer,
      homepage,
      locale,
      menuNavigation: pageLayoutData.menu,
      messages,
      ninetailed: {
        experiences,
        experiments
      },
      pageName: 'homepage',
      webStore
    },
    revalidate: 3600
  };
}
