import React, { useContext } from 'react';
import { ServicesContext } from 'services/context';
import Head from 'next/head';
import { useIntl } from 'react-intl';
import { NextRouter, useRouter } from 'next/router';
import { getDefaultCanonicalUrl, getFormattedMessages } from './helpers';
import baseNames from 'routes/baseNames';
import { Language } from 'types/language';

type Props = {
  children?: React.ReactNode;
  description?: string;
  image?: string;
  noIndex?: boolean;
  title?: string;
  alternatePaths?: { [k in Language]: string };
};

function sanitizeHtml(metaContent: string) {
  return metaContent.replace(/\xA0/g, ' ');
}

function mapAlternatePaths(router: NextRouter) {
  const langAlternates = Object.keys(baseNames).map(countryLocale => {
    const hrefLang = `${baseNames[countryLocale].lang}-${baseNames[countryLocale].country}`;

    const fullPath = getDefaultCanonicalUrl(router, countryLocale);
    return <link rel='alternate' hrefLang={hrefLang} href={fullPath} key={countryLocale} />;
  });

  return langAlternates;
}
// fills the rest of the countries with slugs
function fillAlternatePaths(altPaths: { [k in Language]: string }) {
  const langAlternates = Object.keys(baseNames).map(countryLocale => {
    const href = altPaths[baseNames[countryLocale].lang]?.replace(/^\/+/g, '');
    const hrefLang = `${baseNames[countryLocale].lang}-${baseNames[countryLocale].country}`;
    if (!href) {
      return null;
    }

    const fullPath = ['https://www.aceandtate.com', countryLocale, href].join('/').replace(/^\/|\/$/g, '');

    return <link rel='alternate' hrefLang={hrefLang} href={fullPath} key={countryLocale} />;
  });

  return langAlternates;
}

const defaultMetaImage =
  'https://images.aceandtate.com/image/upload/c_fill,h_630,w_1200/v1573559429/homepage/take_another_look.jpg';

export default function CustomHead(props: Props) {
  const { title, children, description, image, noIndex = false, alternatePaths } = props;
  const router = useRouter();
  const { locale } = router;
  const genericTitle = 'Ace & Tate';
  const ctx = useContext(ServicesContext);
  const intl = useIntl();
  const localizedMessages = getFormattedMessages(intl, ctx.webStore.config);
  const pageDescription = description || localizedMessages.description;
  const pageTitle = title ? `${title} | ${genericTitle}` : genericTitle;
  const canonicalHref = getDefaultCanonicalUrl(router);
  const metaImageUrl = image || defaultMetaImage;

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta charSet='utf-8' />
      {noIndex && <meta name='robots' content='noindex' />}

      <meta name='application-name' content={sanitizeHtml(pageTitle)} />
      <meta name='description' content={sanitizeHtml(pageDescription)} />
      <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.00, minimal-ui'
      />
      {/*
          Google site verification
        */}

      <meta name='google-site-verification' content='BzEkx4crrCpfHH_kyMR95ubpf04klVI_tKCFxMg_Oq0' />
      {!noIndex && <link rel='canonical' href={canonicalHref} key='canonical' />}
      {/*
          Open graph protocol
          Images and info displayed when sharing
          https://developers.facebook.com/docs/sharing/best-practices/#
        */}

      <meta property='og:title' content={pageTitle} />
      <meta property='dc:title' content={pageTitle} />
      <meta property='twitter:title' content={pageTitle} />
      <meta property='og:type' content='website' />
      <meta property='og:description' content={pageDescription} />
      <meta property='og:url' content={canonicalHref} key='og:url' />
      <meta property='og:locale' content={locale as string} />
      <meta property='og:image' content={metaImageUrl} />
      <meta name='twitter:site' content='@aceandtate' />
      <meta name='twitter:creator' content='@aceandtate' />

      {alternatePaths ? fillAlternatePaths(alternatePaths) : mapAlternatePaths(router)}
      <meta name='msapplication-TileColor' content='#FFFFFF' />
      <meta name='msapplication-TileImage' content='/static/favicons/mstile-144x144.png' />
      <meta name='msapplication-square70x70logo' content='/static/favicons/mstile-70x70.png' />
      <meta name='msapplication-square150x150logo' content='/static/favicons/mstile-150x150.png' />
      <meta name='msapplication-wide310x150logo' content='/static/favicons/mstile-310x150.png' />
      <meta name='msapplication-square310x310logo' content='/static/favicons/mstile-310x310.png' />
      {noIndex && <meta name='robots' content='noindex' />}
      {children}
    </Head>
  );
}
