import { defineMessages } from 'react-intl';

const messages = defineMessages({
  descriptionBase: {
    defaultMessage:
      'Shop men or women glasses and sunglasses, handmade from {basePrice} including prescription lenses.',
    id: 'htmlHead.title.base'
  },
  descriptionFreeShippingReturns: {
    defaultMessage: 'Free delivery and returns.',
    id: 'htmlHead.title.freeShippingAndReturns'
  },
  descriptionHTO: {
    defaultMessage: 'Try {count} frames at home for free.',
    id: 'htmlHead.title.hto'
  },
  title: {
    defaultMessage: 'Prescription glasses online from {basePrice}',
    id: 'htmlHead.title'
  }
});

export default messages;
